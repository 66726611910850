<slx-spinner [show]="state.isLoading">
    <div class="slx-high-box">
        <div class="sticky-save-button">
            <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted save" (click)="saveChanges()"
                [disabled]="isSaveDisabled()" [ngClass]="{'disabled-button': isSaveDisabled()}"
                style="cursor: pointer;">
                <i class="fas fa-save slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text">Save Changes</span>
            </button>
            <button class="slx-button slx-min-w90 slx-mobile-adapted cancel" (click)="exportData()"
                [disabled]="isImport" style="cursor: pointer;">
                <i class="fas fa-download slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text">Export</span>
            </button>
            <button class="slx-button slx-min-w90 slx-mobile-adapted cancel" (click)="cancel()">
                <i class="fas fa-times slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text">Cancel</span>
            </button>
            <button class="slx-button slx-min-w90 slx-mobile-adapted delete" (click)="deletePaycodeException()">
                <i class="fas fa-trash slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text">Delete</span>
            </button>
            <kendo-dialog *ngIf="showConfirmationDialog" (close)="onDialogCancel()">
                <kendo-dialog-titlebar>
                    Confirmation
                </kendo-dialog-titlebar>
                <div class="custom-dialog-content-box">
                    <p>Are you sure you want to cancel?<br>You will lose saved changes.</p>
                </div>
                <kendo-dialog-actions class="custom-dialog-actions-box">
                    <button class="slx-button slx-min-w90 slx-mobile-adapted confirm" (click)="onConfirm()">
                        <i class="fas fa-check slx-button__icon"></i>
                        <span class="slx-button__text">Yes</span>
                    </button>
                    <button class="slx-button slx-min-w90 slx-mobile-adapted cancel" (click)="onDialogCancel()">
                        <i class="fas fa-times slx-button__icon"></i>
                        <span class="slx-button__text">No</span>
                    </button>
                </kendo-dialog-actions>
            </kendo-dialog>
        </div>
        <form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
            <!-- Paycode Exceptions Section -->
            <fieldset class="section-container">
                <legend class="section-header">
                    <h4>Pay Code Exception</h4>
                </legend>
                <div class="form-container">
                    <div *ngFor="let dataItem of container?.exceptions || [{}]; let rowIndex = index" class="form-row">
                        <div class="form-column">
                            <label>Pay Code Id</label>
                            <div class="display-value">
                                {{ dataItem.id }}
                            </div>
                        </div>
                        <div class="form-column">
                            <label>Organization Id</label>
                            <div class="display-value">
                                {{ dataItem.exOrganizationId }}
                            </div>
                        </div>
                        <div class="form-column">
                            <label>Organization Level Id</label>
                            <div class="display-value">
                                {{ orgLevelId }}
                            </div>
                        </div>
                        <div class="form-column">
                            <label for="description{{rowIndex}}">
                                Description <span class="required">*</span>
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Title of the paycode</span>
                                </span>
                            </label>
                            <input type="text" [(ngModel)]="dataItem.exceptionDescription"
                                name="exceptionDescription{{rowIndex}}" class="k-textbox spaced-input"
                                [ngClass]="{'invalid': !dataItem.exceptionDescription}" (input)="markExceptionsDirty()"
                                required />
                        </div>
                        <div class="form-column">
                            <label for="screenColor{{rowIndex}}">
                                Screen Color
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">UI indicator for every paycode</span>
                                </span>
                            </label>
                            <input name="screenColor{{rowIndex}}" type="color"
                                [value]="intToHexColor(this.container.exceptions[0].screenColor)"
                                (change)="onColorChange($event)" />
                        </div>
                        <div class="form-column">
                            <label for="workedInd{{rowIndex}}">
                                Count Towards PBJ
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Do these hours count PBJ worked time?</span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.workedInd" name="workedInd{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="acaInd{{rowIndex}}">
                                Count Towards ACA
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Do these hours count towards ACA qualification?</span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.acaInd" name="acaInd{{rowIndex}}"
                                id="acaInd{{rowIndex}}" class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="accrualInd{{rowIndex}}">Accrual</label>
                            <input type="checkbox" [(ngModel)]="dataItem.accrualInd" name="accrualInd{{rowIndex}}"
                                id="accrualInd{{rowIndex}}" class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="discrepancyInd{{rowIndex}}">Discrepancy</label>
                            <input type="checkbox" [(ngModel)]="dataItem.discrepancyInd"
                                name="discrepancyInd{{rowIndex}}" id="discrepancyInd{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exAllowMultiple{{rowIndex}}">Allow Multiple</label>
                            <input type="checkbox" [(ngModel)]="dataItem.exAllowMultiple"
                                name="exAllowMultiple{{rowIndex}}" id="exAllowMultiple{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exCountTowardsOvertimeInd{{rowIndex}}">
                                Count Towards Overtime
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Applies paycode hours to the Overtime threshold</span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.exCountTowardsOvertimeInd"
                                name="exCountTowardsOvertimeInd{{rowIndex}}" class="styled-checkbox"
                                (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exApplyToWeekday{{rowIndex}}">Apply to Weekday</label>
                            <input type="checkbox" [(ngModel)]="dataItem.exApplyToWeekday"
                                id="exApplyToWeekday{{rowIndex}}" name="exApplyToWeekday{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exShowInExceptionGrid{{rowIndex}}">
                                Show in Exception Grid
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Displays as exception on Configuration page</span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.exShowInExceptionGrid"
                                id="exShowInExceptionGrid{{rowIndex}}" name="exShowInExceptionGrid{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exUseInTimesheets{{rowIndex}}">
                                Use in Timesheets
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Allows the employee in ESS to add paycodes to their
                                        timecard</span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.exUseInTimesheets"
                                id="exUseInTimesheets{{rowIndex}}" name="exUseInTimesheets{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="paidInd{{rowIndex}}">
                                Exception Paid at 2nd Rate
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Enables paycode to go into the pay summary on the
                                        timecard</span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.paidInd" id="paidInd{{rowIndex}}"
                                name="paidInd{{rowIndex}}" class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column" *ngIf="!dataItem.paidInd">
                            <label for="ex2ndPayRateExceptionId{{rowIndex}}">Apply This Exception When Paid at 2nd
                                Rate</label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.ex2ndPayRateExceptionId"
                                (valueChange)="markExceptionsDirty()" name="ex2ndPayRateExceptionId{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label [attr.for]="'groupId' + rowIndex">
                                Group
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Mechanism that ties different paycode behaviors
                                        together</span>
                                </span>
                            </label>
                            <kendo-dropdownlist [data]="exceptionGroups" textField="group" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.groupId"
                                (valueChange)="onGroupIdChange($event)" name="groupId{{rowIndex}}"
                                [attr.id]="'groupId' + rowIndex">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column" *ngIf="dataItem.groupId == 4">
                            <label for="exApplyShiftDiff{{rowIndex}}">
                                Apply Shift Diff
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Whenever an abscene is used, that shift differential will apply to the absence</span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.exApplyShiftDiff"
                                id="exApplyShiftDiff{{rowIndex}}" name="exApplyShiftDiff{{rowIndex}}"
                                class="styled-checkbox" (change)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column" *ngIf="dataItem.groupId == 4">
                            <label for="exWorkStatus{{rowIndex}}">Work Status</label>
                            <kendo-dropdownlist [data]="specialCodes" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.exWorkStatus"
                                (valueChange)="markExceptionsDirty()" name="exWorkStatus{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column" *ngIf="dataItem.groupId == 5">
                            <label for="exWorkStatus{{rowIndex}}">Work Status</label>
                            <kendo-dropdownlist [data]="workCodes" textField="name" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.exWorkStatus"
                                (valueChange)="markExceptionsDirty()" name="exWorkStatus{{rowIndex}}"
                                [attr.id]="'exWorkStatus' + rowIndex">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column" *ngIf="dataItem.groupId == 10">
                            <label for="exSecondaryJobCode{{rowIndex}}">Secondary Job Code</label>
                            <input type="number" [(ngModel)]="dataItem.exSecondaryJobCode"
                                name="exSecondaryJobCode{{rowIndex}}" id="exSecondaryJobCode{{rowIndex}}"
                                class="k-textbox spaced-input" (input)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exAllocationType{{rowIndex}}">
                                Allocation Type <span class="required">*</span>
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Grouping of paycodes and how they behave</span>
                                </span>
                            </label>
                            <kendo-dropdownlist [data]="allocationTypes" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.exAllocationType"
                                (valueChange)="markExceptionsDirty()" name="exAllocationType{{rowIndex}}" required
                                [ngClass]="{'invalid': !dataItem.exAllocationType}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-row">
                            <div class="form-column">
                                <label for="exPayDiffType{{rowIndex}}">
                                    Pay Differential Type <span class="required">*</span>
                                    <span class="tooltip-container">
                                        <span class="tooltip-icon">i</span>
                                        <span class="tooltip-text">Determines how the paycode is applied to the
                                            timecard</span>
                                    </span>
                                </label>
                                <kendo-dropdownlist [data]="payDiffTypes" textField="description" valueField="id"
                                    [valuePrimitive]="true" [(ngModel)]="dataItem.exPayDiffType"
                                    (valueChange)="markExceptionsDirty()" name="exPayDiffType{{rowIndex}}" required
                                    [ngClass]="{'invalid': !dataItem.exPayDiffType}">
                                </kendo-dropdownlist>
                            </div>
                            <div class="form-column">
                                <label for="payDiffPct{{rowIndex}}">
                                    Pay Differential Amount
                                    <span class="tooltip-container">
                                        <span class="tooltip-icon">i</span>
                                        <span class="tooltip-text">Dollar amount or percentage amount</span>
                                    </span>
                                </label>
                                <input type="number" [(ngModel)]="dataItem.payDiffPct" name="payDiffPct{{rowIndex}}"
                                    id="payDiffPct{{rowIndex}}" class="k-textbox spaced-input"
                                    (input)="markExceptionsDirty()" />
                            </div>
                        </div>
                        <div class="form-column">
                            <label for="exInterfaceCategoryId{{rowIndex}}">
                                Interface Category
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Determines which column on export the totals get put in
                                        to</span>
                                </span>
                            </label>
                            <kendo-dropdownlist [data]="interfaceCategories" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.exInterfaceCategoryId"
                                (valueChange)="markExceptionsDirty()" name="exInterfaceCategoryId{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label for="exInterface{{rowIndex}}">
                                Interface Code
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">The interface indicator for mapping to the payroll export
                                        to send to payroll vendor</span>
                                </span>
                            </label>
                            <input type="text" [(ngModel)]="dataItem.exInterface" name="exInterface{{rowIndex}}"
                                class="k-textbox spaced-input" (input)="markExceptionsDirty()" />
                        </div>
                        <!-- Radio button group for selecting the display option -->
                        <div class="form-column-full">
                            <div class="radio-buttons">
                                <label>
                                    <input type="radio" name="option" value="Action" [(ngModel)]="selectedOption"
                                        (change)="updateDisplayedFields()" />
                                    Action
                                </label>
                                <label>
                                    <input type="radio" name="option" value="Interval" [(ngModel)]="selectedOption"
                                        (change)="updateDisplayedFields()" />
                                    Interval
                                </label>
                                <label>
                                    <input type="radio" name="option" value="Formula" [(ngModel)]="selectedOption"
                                        (change)="updateDisplayedFields()" />
                                    Formula
                                    <span class="tooltip-container">
                                        <span class="tooltip-icon">i</span>
                                        <span class="tooltip-text">Allows a formula to determine different pay grades
                                            based on employee qualifiers</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <!-- Conditionally displayed fields -->
                        <div class="form-row" *ngIf="selectedOption === 'Action'">
                            <div class="form-column">
                                <label for="exActionStart{{rowIndex}}">
                                    Action Start
                                    <span class="tooltip-container">
                                        <span class="tooltip-icon">i</span>
                                        <span class="tooltip-text">Used to automate a paycode that is not an allocation
                                            of money</span>
                                    </span>
                                </label>
                                <kendo-dropdownlist [data]="exceptionVariables" textField="description"
                                    valueField="description" [valuePrimitive]="true"
                                    [(ngModel)]="this.container.exceptions[0].exActionStart"
                                    (valueChange)="onActionVariableChange($event, 'exActionStart')"
                                    name="exActionStart{{rowIndex}}" id="exActionStart{{rowIndex}}">
                                </kendo-dropdownlist>
                            </div>
                            <div class="form-column">
                                <label for="exActionEnd{{rowIndex}}">
                                    Action End
                                    <span class="tooltip-container">
                                        <span class="tooltip-icon">i</span>
                                        <span class="tooltip-text">Used to automate a paycode that is not an allocation
                                            of money</span>
                                    </span>
                                </label>
                                <kendo-dropdownlist [data]="exceptionVariables" textField="description"
                                    valueField="description" [valuePrimitive]="true"
                                    [(ngModel)]="this.container.exceptions[0].exActionEnd"
                                    (valueChange)="onActionVariableChange($event, 'exActionEnd')"
                                    name="exActionEnd{{rowIndex}}" id="exActionEnd{{rowIndex}}">
                                </kendo-dropdownlist>
                            </div>
                        </div>
                        <div class="form-row" *ngIf="selectedOption === 'Interval'">
                            <div class="form-column">
                                <label for="exActionStartVar{{rowIndex}}">
                                    Action Start Variable
                                    <span class="tooltip-container">
                                        <span class="tooltip-icon">i</span>
                                        <span class="tooltip-text">Used to automate a paycode that is not an allocation
                                            of money</span>
                                    </span>
                                </label>
                                <kendo-dropdownlist [data]="exceptionVariables" textField="description" valueField="id"
                                    [valuePrimitive]="true" [(ngModel)]="this.container.exceptions[0].exActionStartVar"
                                    (valueChange)="onExActionStartVarChange($event)" name="exActionStartVar{{rowIndex}}"
                                    id="exActionStartVar{{rowIndex}}">
                                </kendo-dropdownlist>
                            </div>
                            <div class="form-column">
                                <label for="exActionInterval{{rowIndex}}">
                                    Action Interval
                                    <span class="tooltip-container">
                                        <span class="tooltip-icon">i</span>
                                        <span class="tooltip-text">Used to automate a paycode that is not an allocation
                                            of money</span>
                                    </span>
                                </label>
                                <input type="number" [(ngModel)]="this.container.exceptions[0].exActionInterval"
                                    (input)="markExceptionsDirty()" name="exActionInterval{{rowIndex}}"
                                    id="exActionInterval{{rowIndex}}" class="k-textbox spaced-input" />
                            </div>
                        </div>
                        <div class="form-row" *ngIf="selectedOption === 'Formula'">
                            <div class="form-column">
                                <label for="exFormulaStart{{rowIndex}}">
                                    Formula Start
                                    <span class="tooltip-container">
                                        <span class="tooltip-icon">i</span>
                                        <span class="tooltip-text">Used to automate a paycode that is not an allocation
                                            of money</span>
                                    </span>
                                </label>
                                <input type="text" [(ngModel)]="dataItem.exFormulaStart"
                                    name="exFormulaStart{{rowIndex}}" class="k-textbox spaced-input"
                                    (input)="markExceptionsDirty()" />
                            </div>
                            <div class="form-column">
                                <label for="exFormulaEnd{{rowIndex}}">
                                    Formula End
                                    <span class="tooltip-container">
                                        <span class="tooltip-icon">i</span>
                                        <span class="tooltip-text">Used to automate a paycode that is not an allocation
                                            of money</span>
                                    </span>
                                </label>
                                <input type="text" [(ngModel)]="dataItem.exFormulaEnd" name="exFormulaEnd{{rowIndex}}"
                                    id="exFormulaEnd{{rowIndex}}" class="k-textbox spaced-input"
                                    (input)="markExceptionsDirty()" />
                            </div>
                        </div>
                        <div class="form-column">
                            <label for="exMinimumInterval{{rowIndex}}">
                                Apply Exception if Interval is Not Less Than
                                (mins)
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Minimum minutes applied to a paycode</span>
                                </span>
                            </label>
                            <input type="number" [(ngModel)]="dataItem.exMinimumInterval"
                                name="exMinimumInterval{{rowIndex}}" class="k-textbox spaced-input"
                                (input)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exMaximumAppliedInterval{{rowIndex}}">
                                Maximum Applied Interval
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Maximum threshold of minutes applied to a paycode</span>
                                </span>
                            </label>
                            <input type="number" [(ngModel)]="dataItem.exMaximumAppliedInterval"
                                name="exMaximumAppliedInterval{{rowIndex}}" id="exMaximumAppliedInterval{{rowIndex}}"
                                class="k-textbox spaced-input" (input)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exMinimumAppliedInterval{{rowIndex}}">
                                Minimum Applied Interval
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Minimum threshold of minutes applied to a paycode</span>
                                </span>
                            </label>
                            <input type="number" [(ngModel)]="dataItem.exMinimumAppliedInterval"
                                name="exMinimumInterval{{rowIndex}}" id="exMinimumAppliedInterval{{rowIndex}}"
                                class="k-textbox spaced-input" (input)="markExceptionsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exAddlVariable{{rowIndex}}">
                                Additional Variable Prompt
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Add a retro date to apply to an amount</span>
                                </span>
                            </label>
                            <input type="text" [(ngModel)]="dataItem.exAddlVariable" name="exAddlVariable{{rowIndex}}"
                                id="exAddlVariable{{rowIndex}}" class="k-textbox spaced-input"
                                (input)="markExceptionsDirty()" />
                        </div>
                    </div>
                </div>
            </fieldset>

            <!-- Additional Requirements Section -->
            <fieldset class="section-container">
                <legend class="section-header">
                    <h4>Additional Details</h4>
                </legend>
                <div class="form-container">
                    <div *ngFor="let dataItem of container?.additionalRequirements || [{}]; let rowIndex = index"
                        class="form-row">
                        <div class="form-column">
                            <label for="ignoreBreak{{rowIndex}}">
                                Ignore Break in Meal Calc.
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">
                                        If enabled, will ignore lunch break when calculating gross hours worked
                                    </span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.ignoreBreak" name="ignoreBreak{{rowIndex}}"
                                id="ignoreBreak{{rowIndex}}" class="styled-checkbox"
                                (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="calculateNetWorkTime{{rowIndex}}">
                                Calculate Net Work Time
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">
                                        This option allows pay codes to only apply to net worked time (will subtract time for lunches / not punched in)
                                    </span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.calculateNetWorkTime"
                                name="calculateNetWorkTime{{rowIndex}}" class="styled-checkbox"
                                (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="useContiguousTime{{rowIndex}}">
                                Use Contiguous Time
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">
                                        Combines continous time from previous day with current day
                                    </span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.useContiguousTime"
                                name="useContiguousTime{{rowIndex}}" id="useContiguousTime{{rowIndex}}"
                                class="styled-checkbox" (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="stayWithinExceptionTimeframe{{rowIndex}}">
                                Stay Within Exception
                                Timeframe
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">
                                        Application wll disregard interval and apply earning based on start and end boundary
                                    </span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.stayWithinExceptionTimeframe"
                                id="stayWithinExceptionTimeframe{{rowIndex}}"
                                name="stayWithinExceptionTimeframe{{rowIndex}}" class="styled-checkbox"
                                (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="exportAsDollarAmount{{rowIndex}}">
                                Export as Dollar Amount
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Takes an allocation of hours and converts the dollar
                                        value of the code to an actual dollar amount</span>
                                </span>
                            </label>
                            <input type="checkbox" [(ngModel)]="dataItem.exportAsDollarAmount"
                                name="exportAsDollarAmount{{rowIndex}}" class="styled-checkbox"
                                (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="paidAtHolidayRate{{rowIndex}}">Paid at Holiday Rate</label>
                            <input type="checkbox" [(ngModel)]="dataItem.paidAtHolidayRate"
                                id="paidAtHolidayRate{{rowIndex}}" name="paidAtHolidayRate{{rowIndex}}"
                                class="styled-checkbox" (change)="markAdditionalRequirementsDirty()" />
                        </div>
                        <div class="form-column">
                            <label for="regularOverride{{rowIndex}}">
                                Regular Hours Override
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Specifies a pay code to override to when occuring during REG hours</span>
                                </span>
                            </label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.regularOverrideTemp"
                                (valueChange)="onOverrideChange('regularOverride', $event)"
                                id="regularOverride{{rowIndex}}" name="regularOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>

                        <div class="form-column">
                            <label for="otOverride{{rowIndex}}">
                                Overtime Hours Override
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Specifies a pay code to override to when occuring during OT</span>
                                </span>
                            </label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.otOverrideTemp"
                                (valueChange)="onOverrideChange('otOverride', $event)" name="otOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label for="holidayOverride{{rowIndex}}">Holiday Override</label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description" valueField="id"
                                [valuePrimitive]="true" [(ngModel)]="dataItem.holidayOverrideTemp"
                                (valueChange)="onOverrideChange('holidayOverride', $event)"
                                name="holidayOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label for="overtimeHolidayOverride{{rowIndex}}">
                                Overtime Holiday Hours Override
                                <span class="tooltip-container">
                                    <span class="tooltip-icon">i</span>
                                    <span class="tooltip-text">Identifies another exception that overrides this
                                        exception when applied to Group Type Hours</span>
                                </span>
                            </label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description"
                                valueField="description" [valuePrimitive]="true"
                                [(ngModel)]="dataItem.overtimeHolidayOverride"
                                (valueChange)="markAdditionalRequirementsDirty()"
                                id="overtimeHolidayOverride{{rowIndex}}" name="overtimeHolidayOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-column">
                            <label for="otherCodeOverride{{rowIndex}}">Other Code Override</label>
                            <kendo-dropdownlist [data]="payCodeExceptions" textField="description"
                                valueField="description" [valuePrimitive]="true"
                                [(ngModel)]="dataItem.otherCodeOverride"
                                (valueChange)="markAdditionalRequirementsDirty()" id="otherCodeOverride{{rowIndex}}"
                                name="otherCodeOverride{{rowIndex}}">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                </div>
            </fieldset>
            <!-- Rules Section -->
            <fieldset class="section-container">
                <legend class="section-header">
                    <h4>Rules</h4>
                    <button (click)="addRule()" class="add-rule-button">+</button>
                </legend>
                <div class="form-container">
                    <div *ngIf="container?.rules?.length === 0">
                        <p>No rules. Press + to add.</p>
                    </div>
                    <div *ngFor="let rule of container?.rules || []; let ruleIndex = index" class="form-row rule-item">
                        <div class="form-column-full">
                            <label for="ruleDescription{{ruleIndex}}">Description <span
                                    class="required">*</span></label>
                            <input type="text" [(ngModel)]="rule.ruleDescription" name="ruleDescription{{ruleIndex}}"
                                id="ruleDescription{{ruleIndex}}" class="k-textbox spaced-input"
                                [ngClass]="{'invalid': !rule.ruleDescription}" (input)="markRulesDirty()" required />
                        </div>
                        <div class="form-column-full rule-formula-header">
                            <label for="addRuleFormulaButton">Rule Formulas</label>
                            <button id="addRuleFormulaButton" (click)="addRuleFormula(rule)"
                                class="add-rule-formula-button">
                                +
                            </button>
                        </div>
                        <div class="form-column-full"
                            *ngFor="let formula of rule.ruleFormulas || []; let formulaIndex = index">
                            <div class="form-row rule-formula">
                                <div class="form-column">
                                    <label>Value Type</label>
                                    <div class="radio-buttons">
                                        <label>
                                            <input type="radio" name="valueType1{{ruleIndex}}{{formulaIndex}}"
                                                [(ngModel)]="formula.valueType1" value="value"
                                                (change)="markRulesDirty()"> Value
                                        </label>
                                        <label>
                                            <input type="radio" name="valueType1{{ruleIndex}}{{formulaIndex}}"
                                                [(ngModel)]="formula.valueType1" value="variable"
                                                (change)="markRulesDirty()"> Variable
                                        </label>
                                        <label>
                                            <input type="radio" name="valueType1{{ruleIndex}}{{formulaIndex}}"
                                                [(ngModel)]="formula.valueType1" value="formula"
                                                (change)="markRulesDirty()"> Formula
                                        </label>
                                    </div>
                                </div>
                                <div class="form-column"
                                    *ngIf="formula.valueType1 === 'value' || formula.valueType1 === 'formula'">
                                    <label for="variableId1{{ruleIndex}}{{formulaIndex}}">Variable 1 <span
                                            class="required">*</span></label>
                                    <input type="text" [(ngModel)]="formula.variableId1"
                                        id="variableId1{{ruleIndex}}{{formulaIndex}}"
                                        name="variableId1{{ruleIndex}}{{formulaIndex}}" class="k-textbox spaced-input"
                                        (input)="markRulesDirty()">
                                </div>
                                <div class="form-column" *ngIf="formula.valueType1 === 'variable'">
                                    <label for="variableId1{{ruleIndex}}{{formulaIndex}}">Variable 1 <span
                                            class="required">*</span></label>
                                    <kendo-dropdownlist [data]="exceptionVariables" textField="description"
                                        valueField="description" [valuePrimitive]="true"
                                        [(ngModel)]="formula.variableId1" id="variableId1{{ruleIndex}}{{formulaIndex}}"
                                        name="variableId1{{ruleIndex}}{{formulaIndex}}"
                                        (valueChange)="markRulesDirty()">
                                    </kendo-dropdownlist>
                                </div>
                                <div class="form-column">
                                    <label for="operator{{ruleIndex}}{{formulaIndex}}">Operator</label>
                                    <select [(ngModel)]="formula.operator" name="operator{{ruleIndex}}{{formulaIndex}}"
                                        (change)="markRulesDirty()">
                                        <option value="=">=</option>
                                        <option value="In">In</option>
                                        <option value="Not In">Not In</option>
                                        <option value=">">&gt;</option>
                                        <option value="<">&lt;</option>
                                        <option value="<=">&lt;=</option>
                                        <option value=">=">&gt;=</option>
                                        <option value="<>">&lt;&gt;</option>
                                    </select>
                                </div>
                                <div class="form-column">
                                    <label for="valueType{{ruleIndex}}{{formulaIndex}}">Value Type</label>
                                    <div class="radio-buttons">
                                        <label>
                                            <input type="radio" name="valueType2{{ruleIndex}}{{formulaIndex}}"
                                                [(ngModel)]="formula.valueType2" value="value"
                                                (change)="markRulesDirty()"> Value
                                        </label>
                                        <label>
                                            <input type="radio" name="valueType2{{ruleIndex}}{{formulaIndex}}"
                                                [(ngModel)]="formula.valueType2" value="variable"
                                                (change)="markRulesDirty()"> Variable
                                        </label>
                                        <label>
                                            <input type="radio" name="valueType2{{ruleIndex}}{{formulaIndex}}"
                                                [(ngModel)]="formula.valueType2" value="formula"
                                                (change)="markRulesDirty()"> Formula
                                        </label>
                                    </div>
                                </div>
                                <div class="form-column"
                                    *ngIf="formula.valueType2 === 'value' || formula.valueType2 === 'formula'">
                                    <label for="variableId2{{ruleIndex}}{{formulaIndex}}">Variable 2 <span
                                            class="required">*</span></label>
                                    <input type="text" [(ngModel)]="formula.variableId2"
                                        name="variableId2{{ruleIndex}}{{formulaIndex}}"
                                        id="variableId2{{ruleIndex}}{{formulaIndex}}" class="k-textbox spaced-input"
                                        (input)="markRulesDirty()">
                                </div>
                                <div class="form-column" *ngIf="formula.valueType2 === 'variable'">
                                    <label for="variableId2{{ruleIndex}}{{formulaIndex}}">Variable 2 <span
                                            class="required">*</span></label>
                                    <kendo-dropdownlist [data]="exceptionVariables" textField="description"
                                        valueField="description" [valuePrimitive]="true"
                                        [(ngModel)]="formula.variableId2"
                                        name="variableId2{{ruleIndex}}{{formulaIndex}}"
                                        id="variableId2{{ruleIndex}}{{formulaIndex}}" (valueChange)="markRulesDirty()">
                                    </kendo-dropdownlist>
                                </div>
                                <div class="form-column" style="text-align: right;">
                                    <button (click)="removeRuleFormula(rule, formulaIndex)"
                                        class="remove-rule-formula-button">-</button>
                                </div>
                            </div>
                            <hr class="rule-formula-separator">
                        </div>
                        <div class="form-column-full" style="text-align: right;">
                            <button (click)="removeRule(ruleIndex)" class="remove-rule-button">-</button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</slx-spinner>