import * as _ from 'lodash';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription ,  Observable } from 'rxjs';
import { ModalService } from '../../../../common/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Organization, Department } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { Timeclock, TimeclockContainer, TimeclockRemoveRequest } from '../../../models/index';
import { EditableListComponent } from '../../editableList/editable-list.component';
import { TimeclockRemoveDialogComponent } from '../timeclock-remove-dialog/timeclock-remove-dialog.component';
import { AccessManagementService, TimeclocksApiService } from '../../../services/index';
import { NotificationsService } from '../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service'; // Import NotificationsService

@Component({
  moduleId: module.id,
  selector: 'slx-timeclocks-list',
  templateUrl: 'timeclocks-list.component.html',
  styleUrls: ['timeclocks-list.component.scss'],
  providers: [AccessManagementService]
})
export class TimeclocksListComponent implements OnInit, OnDestroy {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public state: {
    isLoading: boolean;
  };

  public onDeleteSelected: any;
  public onAddItem: any;

  public get container(): TimeclockContainer {
    return this.m_container;
  }
  private m_container: TimeclockContainer;

  public get stateManagement(): AccessManagementService {
    return this.m_stateManagement;
  }
  private m_stateManagement: AccessManagementService;

  private currentOrganization: Organization;
  private currentDepartment: Department;
  private selectedItems: any[] = [];
  private currentOrgLevel: OrgLevel;
  private appConfig: IApplicationConfig;
  private timeclocksApiService: TimeclocksApiService;
  private modalService: ModalService;
  private notificationsService: NotificationsService;
  public showVirtualClockModal: boolean = false;
  public allTimeclocks: Timeclock[] = [];
  public allOrganizations: Organization[] = [];
  public selectedTimeclockIds: number[] = [];
  public selectedOrgIds: number[] = [];
  public filterText: string = '';
  public filterType: string = 'name';
  public filteredTimeclocks: Timeclock[] = [];

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  @ViewChild(EditableListComponent, { static: true })
  private list: EditableListComponent;

  constructor(timeclocksApiService: TimeclocksApiService, modalService: ModalService, notificationService: NotificationsService, stateManagement: AccessManagementService) {
    this.timeclocksApiService = timeclocksApiService;
    this.modalService = modalService;
    this.notificationsService = notificationService;
    this.m_stateManagement = stateManagement;

    this.onDeleteSelected = () => {
      this.deleteSelected();
    };
    this.onAddItem = () => {
      this.addItem();
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.state = {
      isLoading: false
    };

    this.m_stateManagement.allowCorporationLevel = false;
    this.m_stateManagement.allowOrganizationLevel = true;
    this.m_stateManagement.allowDepartmentLevel = true;
    this.state.isLoading = this.m_stateManagement.lockActions = true;

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.state.isLoading = true;
      this.currentOrgLevel = orgLevel;
      this.m_stateManagement.orgLevelType = this.currentOrgLevel.type;

      // Set current organization or department based on orgLevel
      if (this.currentOrgLevel.type === OrgLevelType.organization) {
        this.currentOrganization = new Organization();
        this.currentOrganization.orgLevelId = this.currentOrgLevel.id;
        this.currentOrganization.name = this.currentOrgLevel.name;
        this.currentDepartment = undefined;
      } else if (this.currentOrgLevel.type === OrgLevelType.department) {
        this.currentOrganization = new Organization();
        this.currentOrganization.orgLevelId = this.currentOrgLevel.parentId;
        this.currentOrganization.name = '';
        this.currentDepartment = new Department();
        this.currentDepartment.orgLevelId = this.currentOrgLevel.id;
        this.currentDepartment.name = this.currentOrgLevel.name;
      } else {
        this.currentOrganization = undefined;
        this.currentDepartment = undefined;
      }

      // Promise.all to wait for both getTimeclocks and loadAllTimeclocksAndOrganizations to resolve
      const promises = [
        this.timeclocksApiService.getTimeclocks(this.currentOrgLevel.id).then((container: TimeclockContainer) => {
          this.m_container = container;
          this.m_stateManagement.actions = container.actions;
        })
      ];

      if (this.currentOrgLevel.id === 1) {
        promises.push(this.loadAllTimeclocksAndOrganizations());
      }

      Promise.all(promises)
        .then(() => {
          // Once promises are resolved, set isLoading to false
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        })
        .catch((error) => {
          // Handle errors and ensure loading is stopped
          console.error(error);
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        });
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onItemEditStart(items: Timeclock[]): void {
    this.m_stateManagement.lockActions = true;
  }

  public onItemEditCancel(items: Timeclock[]): void {
    this.m_stateManagement.lockActions = false;
  }

  public get getCurrentOrgLevel(): OrgLevel {
    return this.currentOrgLevel;
  }

  public onItemSave(items: Timeclock[]): void {
    if (!items || items.length === 0) {
      return;
    }
    let item: Timeclock = items[0];
    this.state.isLoading = this.m_stateManagement.lockActions = true;
    if (item.id !== 0) {
      this.timeclocksApiService.saveTimeclock(item, this.currentOrgLevel.id)
        .then(() => {
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        }).catch(() => {
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        });
    } else {
      this.timeclocksApiService.addTimeclock(item, this.currentOrgLevel.id)
        .then((newItem: Timeclock) => {
          item.id = newItem.id;
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        }).catch(() => {
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        });
    }
  }

  public addItem(): void {
    let newItem: Timeclock = new Timeclock();
    newItem.id = 0;
    newItem.organization = this.currentOrganization;
    newItem.department = this.currentDepartment;
    this.list.addItem(newItem);
  }

  public onSelectionChange(items: Timeclock[]): void {
    this.m_stateManagement.selectedItemsCount = items.length;
    this.selectedItems = items;
  }

  private deleteSelected(): void {
    if (!this.selectedItems) {
      return;
    }

    if (this.selectedItems.length === 1) {
      let req: TimeclockRemoveRequest = new TimeclockRemoveRequest();
      req.timeclock = this.selectedItems[0];
      TimeclockRemoveDialogComponent.openDialog(req, this.modalService, (result: boolean, cmd: TimeclockRemoveRequest) => {
        if (result) {
          this.doDelete(cmd);
        }
      });
    } else {
      alert('Mass delete operation not implemented');
    }
  }

  private doDelete(req: TimeclockRemoveRequest): void {
    this.state.isLoading = this.m_stateManagement.lockActions = true;
    this.timeclocksApiService.removeTimeclock(req, this.currentOrgLevel.id)
      .then(() => {
        this.state.isLoading = this.m_stateManagement.lockActions = false;
        _.pullAll(this.container.records, this.selectedItems);
        this.selectedItems = [];
      })
      .catch((e: Error) => {
        this.state.isLoading = this.m_stateManagement.lockActions = false;
      });
  }

  public openVirtualClockModal(): void {
    this.showVirtualClockModal = true;
  }

  // Method to close the modal
  public closeVirtualClockModal(): void {
    this.showVirtualClockModal = false;
    this.selectedTimeclockIds = [];
    this.selectedOrgIds = [];
    this.filterText = "";
    this.applyFilter();
  }

  // Load all timeclocks and organizations for the modal
  private loadAllTimeclocksAndOrganizations(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.state.isLoading = true;

      Promise.all([
        this.timeclocksApiService.getAllTimeclocks().then((container) => {
          this.allTimeclocks = _.orderBy(
            container.records.filter(clock => !clock.isVirtual),
            ['name'],
            ['asc']
          );
          this.filteredTimeclocks = this.allTimeclocks;  // Initialize filtered list
        }),
        this.timeclocksApiService.getAllOrganizations().then((orgs) => {
          this.allOrganizations = _.orderBy(orgs, ['name'], ['asc']); // Sort A to Z
        })
      ])
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.error('Error loading timeclocks and organizations:', error);
          reject(error);
        });
    });
  }

  // Apply filter based on selected type and input text
  public applyFilter(): void {
    if (this.filterType === 'name') {
      // Filter by timeclock name
      this.filteredTimeclocks = this.allTimeclocks.filter(clock =>
        clock.name.toLowerCase().includes(this.filterText.toLowerCase())
      );
    } else if (this.filterType === 'organization') {
      // Filter by organization name
      const matchingOrgIds = this.allOrganizations
        .filter(org => org.name.toLowerCase().includes(this.filterText.toLowerCase()))
        .map(org => org.id);

      this.filteredTimeclocks = this.allTimeclocks.filter(clock =>
        matchingOrgIds.includes(clock.organization.id)
      );
    }
  }

  // Toggle timeclock selection
  public toggleTimeclockSelection(timeclockId: number): void {
    const index = this.selectedTimeclockIds.indexOf(timeclockId);
    if (index > -1) {
      this.selectedTimeclockIds.splice(index, 1);
    } else {
      this.selectedTimeclockIds.push(timeclockId);
    }
  }

  // Toggle organization selection
  public toggleOrgSelection(orgId: number): void {
    const index = this.selectedOrgIds.indexOf(orgId);
    if (index > -1) {
      this.selectedOrgIds.splice(index, 1);
    } else {
      this.selectedOrgIds.push(orgId);
    }
  }

  // Check if the "Create Virtual Clocks" button should be enabled
  public canCreateVirtualClocks(): boolean {
    return this.selectedTimeclockIds.length > 0 && this.selectedOrgIds.length > 0;
  }

  // Create virtual clocks
  public createVirtualClocks(): void {
    if (!this.canCreateVirtualClocks()) {
      return;
    }

    this.state.isLoading = true;
    this.timeclocksApiService.createVirtualTimeclocks(this.selectedTimeclockIds, this.selectedOrgIds)
      .then(() => {
        this.notificationsService.success('Success', 'Virtual timeclocks created successfully.');
        this.refreshTimeclocks();
        this.closeVirtualClockModal();
      })
      .catch((error) => {
        this.notificationsService.error('Error', 'An error occurred while creating virtual timeclocks.');
        console.error(error);
      })
      .finally(() => {
        this.state.isLoading = false;  // Ensure the button gets re-enabled
      });
  }

  // Refresh the timeclocks list after creating virtual clocks
  private refreshTimeclocks(): void {
    this.state.isLoading = true;
    this.timeclocksApiService.getTimeclocks(this.currentOrgLevel.id)
      .then((container: TimeclockContainer) => {
        this.m_container = container;
      })
      .finally(() => {
        this.state.isLoading = false;
      });
  }
}
