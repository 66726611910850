import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { PopperContent } from 'ngx-popper';
import { Subject, Subscription } from 'rxjs';
import { v4 as UUID } from 'uuid';
import { moment } from '../../../../../app/common';
import { ScheduleCycle } from '../../../../organization';
import { ConfirmDialog2Component, ConfirmOptions, ModalService } from "./../../../../../app/common";
import { EmployeeSectionsRotations, EmployeeSectionsSchedule } from './../../../../../app/employee/employee-sections/models';
import { EmployeeSectionsScheduleApiService } from './../../../../../app/employee/employee-sections/services';
import { NotificationsService } from './../../../../core/components';
import { AvailShifts, FilterRotationOptions, FutureValidationShift, IAvailShifts, IDays, IFilterData, INoRotationEmployeeList, IScheduleDays, IScheduleRotationPermisions, IScheduleRotationTemplate, ScheduleRotationTemplate } from './../../../../scheduler/models/schedule-rotation/schedule-rotation-template.model';
import { ScheduleRotationsManagementService } from './../../../../scheduler/services/scheduled-rotation-template/schedule-rotations-management.service';
import { ScheduledRotationMapService } from './../../../../scheduler/services/scheduled-rotation-template/scheduled-rotation-map.service';
import { SrtViewIndividualScheduleDialogComponent } from './srt-view-individual-schedule-dialog/srt-view-individual-schedule-dialog.component';

@Component({
  selector: 'slx-scheduled-rotations-employee-grid',
  templateUrl: './scheduled-rotations-employee-grid.component.html',
  styleUrls: ['./scheduled-rotations-employee-grid.component.scss']
})
export class ScheduledRotationsEmployeeGridComponent implements OnInit {
  @Input() public orgLevelId: number;
  @Input() public startDate: string;
  @Input() public endDate: string;
  permissions: IScheduleRotationPermisions;
  @ViewChild('excelexport', { static: true })
  excelexport: ExcelExportComponent;
  @ViewChild('kendoGrid') public employeeGrid: any;
  @ViewChild('shiftListId') public someDiv: ElementRef;
  employeeList: IScheduleRotationTemplate[] = [];
  dates: IDays[] = [];
  week: number = 0;
  weekOfDays: IDays[] = [];
  loadDataSubscription: Subscription;
  public dateRangeOfCurrentWeek: string;
  public isModify: boolean = false;
  public count: number = 1;
  public isLoading: boolean;
  public totalHours: number = 0;
  public isView: boolean = true;
  public isEditView: boolean;
  public currentWeek: IDays[] = [];
  setBorder: boolean;

  public tempData: any[] = [];
  public overTimeProceed: boolean;
  public isOverTimeDisable: boolean;
  public overLapData: { employee: ScheduleRotationTemplate, day: IDays };
  public shiftReducer$ = new Subject<any>();
  public addAction: string = 'Add Action';
  public removeAction: string = 'Remove Action';
  public exportEmployeeData: any = [];
  public updatedNoEmpRotationList: INoRotationEmployeeList[];
  public myFilters: CompositeFilterDescriptor = {
    logic: 'or',
    filters: []
  };
  public modalService: ModalService;
  public rotationsSection: EmployeeSectionsRotations;
  public filterOptions: FilterRotationOptions;
  public isExpand: boolean = false;

  public expandedPageHeight = window.innerHeight - 410;
  public collapsedPageHeight = window.innerHeight - 285;

  @HostListener("window:resize", ['$event'])
  onResize(event) {
    this.expandedPageHeight = event.target.innerHeight - 410;
    this.collapsedPageHeight = event.target.innerHeight - 285;
  }

  constructor(public srtManService: ScheduleRotationsManagementService,
    public mapService: ScheduledRotationMapService,
    public notificationService: NotificationsService,
    modalService: ModalService, public empScheduleApiService: EmployeeSectionsScheduleApiService,
    public elementRef: ElementRef, public changeDetect: ChangeDetectorRef) {
    this.srtManService.isExpand$.subscribe((isExpand: boolean) => {
      this.isExpand = isExpand;
    });
    this.modalService = modalService;
    this.srtManService.payCycle.subscribe((dateRange: ScheduleCycle) => {
      this.dates = this.srtManService.dates;
      this.startDate = moment(dateRange.startDate).format('MM/DD/YYYY');
      this.endDate = moment(dateRange.endDate).format('MM/DD/YYYY');
      this.setWeekOfDays();
      this.count = 1;
      this.enumerateDaysBetweenDates(this.startDate, this.endDate);
      this.srtManService.isLoading = true;
    });

    this.srtManService.employeeList$.subscribe((employeeData: IScheduleRotationTemplate[]) => {
      this.employeeList = employeeData;
      this.srtManService.actualEmployeeData = employeeData;
      this.currentWeek = this.srtManService.weekOfDays;
      this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
    });

    this.loadDataSubscription = this.srtManService.subscribeToLoadDataSubscription((data: any) => {
      this.employeeList = data[1];
    });

    this.srtManService.permissions$.subscribe((permissions) => {
      this.permissions = permissions;
      this.isModify = this.permissions.modify;
    });

    this.shiftReducer$.subscribe((data) => {
      if (data[4] === this.addAction) {
        this.srtManService.shiftReducer(data);
        if(data[0].isNewRotation) {
          this.srtManService.isNewRotationAdded = true;
          this.changeDetect.detectChanges();
        }
      }
      if (data[4] === this.removeAction) {
        this.srtManService.deleteShiftFromEmployee(data);
      }
    });

    this.srtManService.exportRotation$.subscribe((isExport: boolean) => {
      if (isExport) {
        this.processDataForExport();
      }
    });

    this.srtManService.noRotationEmployeeList$.subscribe((data: INoRotationEmployeeList[]) => {
      this.updatedNoEmpRotationList = data;
    });

    this.srtManService.filterRotationsRecord$.subscribe((data: FilterRotationOptions) => {
      this.filterOptions = this.srtManService.filterRotationsRecord;
    });
  }

  ngOnInit() {
    this.filterOptions = this.srtManService.filterRotationsRecord;
    this.srtManService.defaultGridFilterOptions = this.srtManService.getSelectedShowStaffOptions(this.srtManService.filterRotationsRecord.showStaff);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['orgLevelId'] || changes['startDate'] || changes['endDate']) {
      if (this.orgLevelId && this.startDate && this.endDate) {
        this.srtManService.getEmployeeRotations(this.orgLevelId, this.startDate, this.endDate);
      }
    }
  }

  public dateName(days: any) {
    const date = moment(days.date).format('MM/DD');
    return this.isViewWeekDate ? `${_.upperCase(days.day)} (${date})` : `${_.upperCase(days.day)}`;
  }

  public formatDate(days: any) {
    return moment(days.date).format('MM/DD/YYYY');
  }

  public setWeekOfDays() {
    const weekDays = _.cloneDeep(this.dates);
    const index = this.week === 0 ? this.week : this.week * 7 - 1;
    this.weekOfDays = weekDays.splice(index, 7);
    this.currentWeek = this.weekOfDays;
    this.srtManService.currentWeek = this.weekOfDays;
    this.dateRangeOfCurrentWeek = `${moment(this.weekOfDays[0].date).format('ll')} - ${moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll')}`;
  }

  onDragEnter(e: DragEvent) {
    this.changeDetect.detach();
  }


  allowDrop(e, employee: ScheduleRotationTemplate, day: IDays) {
    const shift = this.srtManService.draggedShift;
    if ((employee.employeeId !== 0 || (employee.employeeId === 0)) && !this.isEditView) {
      if (shift.weekDay === day.fullDayName && employee.activationDate <= day.date && day.date <= employee.terminationDate) {
        const index = employee.scheduleDays.findIndex(x => x.day.formatDate === day.formatDate);
        if (index >= 0) {
          let isOtherPosition = true;
          if (shift.jobCode === employee.jobCode || employee.secondaryJobCodes.includes(shift.jobCode) || employee.positionGroupedJobCodes.includes(shift.jobCode)) {
            isOtherPosition = false;
          }
          const isOverlapped = this.isTimeOverlapped(shift, employee.scheduleDays[index].shifts);
          if (isOverlapped === true || isOtherPosition === true) {
            employee.scheduleDays[index].isOverLap = true;
            setTimeout(function () {
              employee.scheduleDays[index].isOverLap = false;
            }, 3000);
          } else {
            e.preventDefault();
          }
        }
      }
    }
  }

  isOverLap(employee: ScheduleRotationTemplate, day: IDays): boolean {
    const index = employee.scheduleDays.findIndex(x => x.day.formatDate === day.formatDate);
    return index >= 0 ? employee.scheduleDays[index].isOverLap : false;
  }

  getEmployeeAssignedShifts(employee: ScheduleRotationTemplate, day: IDays) {
    const shiftIndex = employee.scheduleDays.findIndex(x => x.day.formatDate === day.formatDate);
    return shiftIndex >= 0 ? employee.scheduleDays[shiftIndex].shifts : [];
  }

  drop(e, employee: ScheduleRotationTemplate, day: IDays) {
    this.changeDetect.reattach();
    e.preventDefault();
    const shift = (JSON.parse(e.dataTransfer.getData('shift')) as AvailShifts);
    shift.isView = false;
    shift.isWhiteColor = this.setPrimarySecondaryColors(employee, shift);
    const isPositionGrouped = employee.jobCode === shift.jobCode || employee.onlySecondaryJobCodes.some(x => x.jobCode === shift.jobCode)
    shift.isPositionGroupedShift = !isPositionGrouped;
    this.shiftReducer$.next([employee, shift, day, this.srtManService.ShiftList, this.addAction]);
    this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
    this.isEditView = (!shift.isView || employee.isOverTime) ? true : false;
    this.srtManService.isEditViewSaveDisable = this.isEditView;
    // this.setDragabble();
  }

  public removeShift(data: IAvailShifts, employee: ScheduleRotationTemplate, day: IDays) {
    data.scheduleDate = day.formatDate;
    this.shiftReducer$.next([employee, data, day, this.srtManService.ShiftList, this.removeAction]);
    let empIndex = employee.scheduleDays.findIndex(x => x.day.formatDate === day.formatDate);
    employee.scheduleDays[empIndex].shifts.push(...this.tempData);
    this.isEditView = this.isEditView ? false : true;
    this.srtManService.isEditViewSaveDisable = this.isEditView;
    _.map(employee.scheduleDays, (i) => _.map(i.shifts, (j) => j.isView = true));
    // this.setDragabble();
  }

  private setDragabble() {
    this.srtManService.isDragabble$.next(this.isEditView);
  }

  public weekCountIncrement(isIncremented: boolean) {
    isIncremented ? this.count++ : this.count--;
    const weekDays = _.cloneDeep(this.dates);
    if (this.count === 1) {
      this.weekOfDays = weekDays.splice(0, 7);
      this.currentWeek = this.weekOfDays;
      this.srtManService.currentWeek = this.currentWeek;
      this.updateCurrentWeek(this.srtManService.filterShiftData, this.currentWeek);
      this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
      this.dateRangeOfCurrentWeek = `${moment(this.weekOfDays[0].date).format('ll')} - ${moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll')}`;
    }
    if (this.count === 2) {
      this.weekOfDays = weekDays.splice(7, 7);
      this.currentWeek = this.weekOfDays;
      this.srtManService.currentWeek = this.currentWeek;
      this.updateCurrentWeek(this.srtManService.filterShiftData, this.currentWeek);
      this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
      this.dateRangeOfCurrentWeek = `${moment(this.weekOfDays[0].date).format('ll')} - ${moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll')}`;
    }
    if (this.count === 3) {
      this.weekOfDays = weekDays.splice(14, 7);
      this.currentWeek = this.weekOfDays;
      this.srtManService.currentWeek = this.currentWeek;
      this.updateCurrentWeek(this.srtManService.filterShiftData, this.currentWeek);
      this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
      this.dateRangeOfCurrentWeek = `${moment(this.weekOfDays[0].date).format('ll')} - ${moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll')}`;
    }
    if (this.count === 4) {
      this.weekOfDays = weekDays.splice(21, 7);
      this.currentWeek = this.weekOfDays;
      this.srtManService.currentWeek = this.currentWeek;
      this.updateCurrentWeek(this.srtManService.filterShiftData, this.currentWeek);
      this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
      this.dateRangeOfCurrentWeek = `${moment(this.weekOfDays[0].date).format('ll')} - ${moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll')}`;
    }
  }

  public addNewRotation() {
    if(this.srtManService.isNewRotationAdded) return;
    this.myFilters = {
      logic: 'or',
      filters: []
    };
    this.srtManService.setMandatoryChecksForFilter();
    this.srtManService.emptyAddRotationSequenceCount++;
    if (this.employeeList.find(x => x.employeeId === 0 && x.jobCode === 0) === undefined) {
      let data: IScheduleRotationTemplate = {
        id: UUID(),
        departmentId: 0,
        departmentName: '',
        name: '',
        employeeId: 0,
        employeeType: '',
        jobCode: 0,
        positionName: '',
        shiftId: 0,
        shiftName: '',
        employeeTypeName: '',
        scheduleDays: this.srtManService.emptyScheduleDates,
        totalHours: 0,
        isPrimary: true,
        isDifferentDepartment: false,
        state: null,
        isOverTime: false,
        otHours: 40,
        rotationCount: 0,
        activationDate: new Date(this.startDate),
        terminationDate: new Date('06/06/2079'),
        secondaryJobCodes: '',
        primaryPositionGroupName: '',
        isNewRotation: true,
        homeShiftGroupId: 0,
        homeShiftGroupDescription: '',
        positionGroupId: 0,
        slotId: 0,
        isNewlyAssigned: false,
        isEmptySlot: false,
        seqId: this.srtManService.emptyAddRotationSequenceCount,
        positionGroupName: '',
        shiftGroupName: '',
        empPayPeriod: '',
        hasRotations: false,
        isAgencyEmployee: false,
        isFullTime: null,
        onlySecondaryJobCodes: null,
        maskedJobCode: null,
        positionGroupedJobCodes: null,
        otherDepartmentSecondaryJobCodes: null
      };
      if (this.isAnyFilterOptionSelected) {
        this.employeeList.splice(0, 0, data);
        this.srtManService.actualEmployeeData.splice(0, 0, data);
      }
      else {
        this.srtManService.actualEmployeeData.splice(0, 0, data);
      }
      this.changeDetect.detectChanges();
    }
  }

  public setEdit(e: boolean, shift: IAvailShifts, dataItem: ScheduleRotationTemplate, date: any) {
    if (shift.isLock) return;
    if (e && !shift.isAssignedShift && !dataItem.isNewRotation) {
      this.srtManService.updateModifiedEntry(dataItem, shift);
    }
    dataItem.isOverTime = false;
    this.isView = e;
    shift.isView = e;
    this.isEditView = !e ? true : false;
    this.srtManService.isEditViewSaveDisable = !e;
    this.setDragabble();
    this.overTimeProceed = false;
    this.srtManService.empScheduleEntries = this.mapService.employeeScheduleEntriesCheck([dataItem, shift, date, this.srtManService.ShiftList, null])
    if (e && !shift.isAssignedShift) {
      const data = this.setFutureValidationShift(shift.frequency, dataItem, shift, this.srtManService.empScheduleEntries);
      if (data) {
        this.srtManService.futureDateValidation(data, dataItem, shift, date);
        this.srtManService.shiftEdit$.subscribe((data) => {
          this.isEditView = (data[0] && data[1].id === shift.id && data[1].scheduleDate === shift.scheduleDate) ? true : false;
          shift.isView = (data[0] && data[1].id === shift.id && data[1].scheduleDate === shift.scheduleDate) ? false : true;
          this.srtManService.isEditViewSaveDisable = this.isEditView;
        });
        this.srtManService.shiftOverTimeProceed$.subscribe((data) => {
          if (!data[0] && data[1].id === shift.id && data[1].scheduleDate === shift.scheduleDate) {
            this.isEditView = true;
            shift.isView = false;
            this.srtManService.isEditViewSaveDisable = this.isEditView;
          }
        });
      }
    }
  }

  public isOverTimeProceed(e) {
    this.overTimeProceed = e ? true : false;
  }

  public scrollUp() {
    this.someDiv.nativeElement.scrollBy({ top: 200, left: 0, behavior: 'smooth' });
  }

  public scrollDown() {
    this.someDiv.nativeElement.scrollBy({ top: -200, left: 0, behavior: 'smooth' });
  }

  private isTimeOverlapped(currentShift: AvailShifts, allShifts: AvailShifts[]): boolean {
    const currentStart = new Date().toLocaleDateString() + ' ' + new Date(currentShift.startTime).toLocaleTimeString();
    const startDateAddedHoursDate = moment(currentStart).add(moment.duration(currentShift.hours, 'hours'));
    const currentEnd = moment(startDateAddedHoursDate).format('M/DD/YYYY') + ' ' + new Date(currentShift.endTime).toLocaleTimeString();
    let date1: moment.Moment = moment(currentStart);
    let date2: moment.Moment = moment(currentEnd);
    let isOverlapped: boolean = false;
    allShifts.forEach(shift => {
      const start = new Date().toLocaleDateString() + ' ' + new Date(shift.startTime).toLocaleTimeString();
      const startAddedHoursDate = moment(start).add(moment.duration(shift.hours, 'hours'));
      const end = moment(startAddedHoursDate).format('M/DD/YYYY') + ' ' + new Date(shift.endTime).toLocaleTimeString();
      let date3: moment.Moment = moment(start);
      let date4: moment.Moment = moment(end);
      if ((this.isDateRangeOverlapped(moment(date1), moment(date2), moment(date3), moment(date4), true) || (moment.range(date3, date4).contains(date1) || moment.range(date3, date4).contains(date2)))) {
        if (!date1.isSame(date4) && !date2.isSame(date3)) {
          isOverlapped = true;
        }
      }
    });
    return isOverlapped;
  }

  public isDateRangeOverlapped(startDate1: moment.Moment, endDate1: moment.Moment, startDate2: moment.Moment, endDate2: moment.Moment, strict: boolean = true): boolean {
    if (strict) {
      return startDate1.isSameOrBefore(endDate2) && endDate1.isSameOrAfter(startDate2);
    }
    return startDate1.isBefore(endDate2) && endDate1.isAfter(startDate2);
  }

  public updateCurrentWeek(shift, date) {
    const availableShifts = this.mapService.mapScheduleRotationAvailShiftsData(shift, date);
    this.srtManService.availableShifts$.next(availableShifts);
  }

  public getSelectedEmp(empData: { emp: IScheduleRotationTemplate, empId: number }) {
    let index = this.srtManService.actualEmployeeData.findIndex(x => x.employeeId === empData.empId);
    this.fillEmployeeDetails(empData, index);
    if (index >= 0) {
      const element = this.srtManService.actualEmployeeData.splice(index, 1)[0];
      element.seqId = empData.emp.seqId;
      element.scheduleDays = empData.emp.scheduleDays;
      element.slotId = empData.emp.slotId;
      element.isNewlyAssigned = empData.emp.isNewRotation;
      element.isEmptySlot = empData.emp.isEmptySlot;
      const currentIndex = this.srtManService.actualEmployeeData.findIndex(x => x === empData.emp);
      this.updateNoRotationEmployeeList(element);
      this.srtManService.assignedEmployeeAndEmptySlotEmployee.push(this.srtManService.actualEmployeeData[currentIndex]);
      this.srtManService.isNewRotationAdded = true;
      this.changeDetect.detectChanges();
      this.srtManService.storeAssignedSlot$.next();
      let uniqueData = _.uniqBy(this.srtManService.actualEmployeeData, (e) => {
        return e['id'];
      });
      this.srtManService.employeeList$.next(uniqueData);
      this.srtManService.onFilterChange();
    }
  }

  public fillEmployeeDetails(empData, index) {
    this.srtManService.actualEmployeeData[index].isNewRotation = true;
    empData.emp.employeeId = this.srtManService.actualEmployeeData[index].employeeId;
    empData.emp.name = this.srtManService.actualEmployeeData[index].name;
    empData.emp.employeeType = this.srtManService.actualEmployeeData[index].employeeType;
    empData.emp.employeeTypeName = this.srtManService.actualEmployeeData[index].employeeTypeName;
    empData.emp.jobCode = this.srtManService.actualEmployeeData[index].jobCode;
    empData.emp.positionName = this.srtManService.actualEmployeeData[index].positionName ? this.srtManService.actualEmployeeData[index].positionName.trim() : null;
    empData.emp.shiftId = this.srtManService.actualEmployeeData[index].shiftId;
    empData.emp.shiftName = this.srtManService.actualEmployeeData[index].shiftName ? this.srtManService.actualEmployeeData[index].shiftName.trim() : null;
    empData.emp.isPrimary = this.srtManService.actualEmployeeData[index].isPrimary;
    empData.emp.isDifferentDepartment = this.srtManService.actualEmployeeData[index].isDifferentDepartment;
    // empData.emp.scheduleDays =  this.srtManService.actualEmployeeData[index].scheduleDays
    empData.emp.otHours = this.srtManService.actualEmployeeData[index].otHours;
    empData.emp.rotationCount = this.srtManService.actualEmployeeData[index].rotationCount;
    empData.emp.terminationDate = this.srtManService.actualEmployeeData[index].terminationDate;
    empData.emp.activationDate = new Date(this.srtManService.actualEmployeeData[index].activationDate);
    empData.emp.primaryGroupId = this.srtManService.actualEmployeeData[index].primaryGroupId;
    empData.emp.primaryPositionGroupName = this.srtManService.actualEmployeeData[index].primaryPositionGroupName;
    empData.emp.isNewRotation = this.srtManService.actualEmployeeData[index].isNewRotation;
    empData.emp.homeShiftGroupId = this.srtManService.actualEmployeeData[index].homeShiftGroupId;
    empData.emp.homeShiftGroupDescription = this.srtManService.actualEmployeeData[index].homeShiftGroupDescription;
    empData.emp.positionGroupId = this.srtManService.actualEmployeeData[index].positionGroupId;
    empData.emp.isNewlyAssigned = true;
    empData.emp.isEmptySlot = true;
    empData.emp.isFullTime = this.srtManService.actualEmployeeData[index].isFullTime;
    empData.emp.positionGroupId = this.srtManService.actualEmployeeData[index].positionGroupId;
    empData.emp.positionGroupName = this.srtManService.actualEmployeeData[index].positionGroupName;
    empData.emp.onlySecondaryJobCodes = this.srtManService.actualEmployeeData[index].onlySecondaryJobCodes;
    return empData;
  }

  public setSelectedEmpPos(pos: any) {
    let empIndex = this.srtManService.actualEmployeeData.findIndex(x => x.employeeId === 0 && x.jobCode === 0);
    this.srtManService.actualEmployeeData[empIndex].jobCode = pos.id;
    this.srtManService.actualEmployeeData[empIndex].positionName = pos.name;
    this.srtManService.actualEmployeeData[empIndex].isEmptySlot = pos.isEmptySlot;
    this.srtManService.actualEmployeeData[empIndex].isPrimary = true;
    this.srtManService.assignedEmployeeAndEmptySlotEmployee.push(this.srtManService.actualEmployeeData[empIndex]);
  }

  processDataForExport() {
    this.exportEmployeeData = [];
    type columns = { [key: string]: string }
    //let exportData = this.srtManService.filterEmployeeData;
    let exportData = this.employeeGrid.data.data;
    if (exportData.length > 0) {
      exportData.forEach(data => {
        let isDataExists: boolean = true;
        let index: number = 0;
        while (isDataExists) {
          const employeeData: columns = {}
          isDataExists = false;
          employeeData.EmployeeName = data.name;
          employeeData.PositionGroup = data.positionGroupName;
          employeeData.Position = data.positionName;
          employeeData.HomeShift = data.shiftName;
          data.scheduleDays.forEach((dayData) => {
            const lastIndex = dayData.shifts.length - 1;
            if (lastIndex >= 0 && index <= lastIndex) {
              employeeData[dayData.day.dayName] = !dayData.shifts[index].isJobCodeHidden ? `${dayData.shifts[index].shiftName} (${dayData.shifts[index].unitName})` : '';
            } else {
              employeeData[dayData.day.dayName] = '';
            }

            if (lastIndex > index) {
              isDataExists = true;
            }
          });
          this.exportEmployeeData.push(employeeData);
          index++;
        }
      });
      this.excelexport.data = this.exportEmployeeData;
      this.excelexport.save();
    }
  }

  public enumerateDaysBetweenDates(startDate, endDate) {
    let dates: IScheduleDays[] = [];
    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');
    const day = moment(currDate).format('ddd');
    const currentData: IDays = {
      day: day,
      fullDayName: moment(currDate).format('dddd'),
      date: currDate.clone().toDate(),
      dayName: `${day}(${moment(currDate).format('MM/DD')})`,
      formatDate: moment(currDate).format('MM/DD/YYYY')
    };
    dates.push({ day: currentData, isOverLap: false, shifts: [] });
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      const day = moment(currDate).format('ddd');
      const data: IDays = {
        day: day,
        fullDayName: moment(currDate).format('dddd'),
        date: currDate.clone().toDate(),
        dayName: `${day}(${moment(currDate).format('MM/DD')})`,
        formatDate: moment(currDate).format('MM/DD/YYYY')
      };
      dates.push({ day: data, isOverLap: false, shifts: [] });
    }
    this.srtManService.emptyScheduleDates = dates;
  }

  public repeatChange(e, employee, shift) {
    shift.frequency = JSON.parse(e.target.value);
  }

  public setPrimarySecondaryColors(employee: ScheduleRotationTemplate, shift: AvailShifts) {
    let jobCode = shift.jobCode;
    let isPurpleIndex = -1;
    if (employee.onlySecondaryJobCodes) {
      isPurpleIndex = employee.onlySecondaryJobCodes.findIndex(x => x.jobCode === jobCode);
    }

    if (isPurpleIndex < 0 && employee.isDifferentDepartment && employee.positionGroupedJobCodes) {
      isPurpleIndex = employee.positionGroupedJobCodes.findIndex(x => x === jobCode);
    }
    if (employee.employeeId !== 0 && isPurpleIndex >= 0) {
      return false;
    }
    return true;
  }

  public updateNoRotationEmployeeList(emp) {
    this.updatedNoEmpRotationList = _.filter(this.updatedNoEmpRotationList, (item: any) => item.employeeId != emp.employeeId);
    this.srtManService.noRotationEmployeeList$.next(this.updatedNoEmpRotationList);
  }

  public setFutureValidationShift(frequencyCount: number, employee: ScheduleRotationTemplate, shift, shifts) {
    const data: FutureValidationShift = new FutureValidationShift();
    data.employeeId = employee.isEmptySlot ? 0 : employee.employeeId;
    data.shiftId = shift.shiftId;
    data.frequencyId = frequencyCount;
    data.scheduleDate = shift.scheduleDate;
    data.shiftStartTime = shift.startTime;
    data.shiftEndTime = shift.endTime;
    data.empOTHours = employee.otHours;
    data.shiftHours = shift.hours;
    data.scheduleStartDate = moment(this.startDate).format('MM/DD/YYYY');
    data.scheduleEndDate = moment(this.endDate).format('MM/DD/YYYY');
    data.rotationsRequest = shifts;
    data.slotId = shift.slotId;
    data.orgLevelId = this.orgLevelId;
    data.isAlreadyAssignedShift = shift.isAssignedShift;
    return data;
  }

  public viewIndividualSchedule(empData: ScheduleRotationTemplate) {
    this.srtManService.employeeLoader = true;
    empData.empPayPeriod = `${moment(this.startDate).format('MM/DD/YYYY')} - ${moment(this.endDate).format('MM/DD/YYYY')}`;
    this.empScheduleApiService.getScheduleSection(empData.employeeId).then((data: EmployeeSectionsSchedule) => {
      this.srtManService.employeeLoader = false;
      this.rotationsSection = data.rotationsSubsection;
      SrtViewIndividualScheduleDialogComponent.openDialog(empData, this.rotationsSection, this.modalService, (result: boolean) => {
      });
    });
  }

  public onSettingsChanged(popperContent: PopperContent, isApply: boolean): void {
    popperContent.hide();
    if (!isApply) {
      this.srtManService.isGridFilterApplied$.next(false);
      if(this.employeeList.some(x => x.employeeId == 0)) {
        let ids = [3, 4];
        this.srtManService.filterRotationsRecord.showStaff = this.srtManService.filterRotationsRecord.showStaff.map(item => {
            if (ids.includes(item.id)) {
                item.isSelected = true;
            }
            return item;
        });
      }
      return;
    }
    else {
      this.srtManService.isGridFilterApplied$.next(true);
      this.srtManService.onFilterChange();
    }
  }

  checkedChange(filterName: IFilterData[], property: string, event: boolean) {
    let ind = filterName.findIndex(x => x.name === property);
    filterName[ind].isSelected = event;
  }

  get isViewWeekDate(): boolean {
    return this.srtManService.viewWeeksDate;
  }

  get isAnyFilterOptionSelected(): boolean {
    if (this.srtManService.selectedPos.length > 0
      || this.srtManService.selectedPosGroup.length > 0
      || this.srtManService.selectedUnit.length > 0
      || this.srtManService.selectedShiftGroup.length > 0
      || this.srtManService.filterRotationsRecord.showStaff.some(x => x.isSelected)
      || this.srtManService.filterRotationsRecord.positionOptions.agencyStaff.isSelected
      || this.srtManService.filterRotationsRecord.positionOptions.emptyRotation.isSelected
      || this.srtManService.filterRotationsRecord.positionOptions.primaryPosition.isSelected
      || this.srtManService.filterRotationsRecord.positionOptions.secondaryPosition.isSelectAll) {
      return true;
    }
    else {
      return false;
    }
  }
  deleteEmptyRotations(rotation: IScheduleRotationTemplate) {
    const message = `Do you want to delete this rotation?
    The shifts assigned to this rotation will now be in the Available shifts section to be assigned to other rotations.`;
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Delete';
    options.buttonCanceltext = 'Return';
    ConfirmDialog2Component.openDialog(
      'Warning',
      message,
      this.modalService,
      (result: boolean) => {
        if(result){
          if (rotation.slotId !== 0) {
            this.srtManService.DeleteEmptyRotation(rotation.slotId).then(() => {
              this.srtManService.isNewRotationAdded = false;
              this.srtManService.getEmployeeRotations(this.orgLevelId, this.startDate, this.endDate);
              this.srtManService.getScheduleRotationAvailShifts(this.orgLevelId, this.startDate, this.endDate);
            });
          } else {
            this.employeeList = this.employeeList.filter(x => x.id !== rotation.id);
            this.srtManService.actualEmployeeData = this.srtManService.actualEmployeeData.filter(x => x.id !== rotation.id);
            this.srtManService.assignedEmployeeAndEmptySlotEmployee = this.srtManService.assignedEmployeeAndEmptySlotEmployee.filter(x=> x.id!== rotation.id);
            this.srtManService.setMandatoryChecksForFilter();
            this.srtManService.isNewRotationAdded = false;
          }
        }
      }, options);

  }
}

