import { NavigationMenuRedirectService } from './../../services/navigation/navigation-menu-redirect.service';
import * as _ from 'lodash';
import { BenAdminMenuManagementService } from './../../services/navigation/ben-admin/ben-admin-menu-management.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, ElementRef, ChangeDetectorRef, HostListener, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

import { unsubscribe, destroyService } from '../../../core/decorators/index';
import { UserApplication } from '../../../state-model/models/index';
import { NavigationMenuManagementService } from '../../services/index';
import { NavigationMenuItem } from '../../models/index';
import { appConfig } from '../../../app.config';
import { scheduleMicrotask } from '../../../core/utils/index';
import { ApplicationStateBusService } from '../../services/index';
import { MessageCenterConfigService } from '../../services/index';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../app-settings/services';


@Component({
  moduleId: module.id,
  selector: 'slx-navigation-menu',
  templateUrl: 'navigation-menu.component.html',
  styleUrls: ['navigation-menu.component.scss'],
  providers: [NavigationMenuManagementService]
})
export class NavigationMenuComponent implements OnInit, OnChanges {
  @ViewChild('slxNavMenu', { static: false })
  public navMenu: ElementRef<HTMLUListElement>;

  @Input()
  public isOpened: boolean;

  public menuItems: NavigationMenuItem[];
  public application: UserApplication;
  public selectedApplicationMenu: NavigationMenuItem;
  public expandedTopLevelId: number;
  public expandedGropLevelId: number;
  public noData: boolean;
  public unavailableText = 'This menu item is unavailable at the selected organization level.';
  public showFavorites: boolean;
  public posTop: number;
  public posLeft: number;
  public iconShift: number;

  @unsubscribe()
  private loadMenuSubscription: Subscription;
  @unsubscribe()
  private preselectedOrgLevelSubscription: Subscription;
  @unsubscribe()
  private applicationSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private menuChangedSubscription: Subscription;

  @destroyService()
  private menuManagement: NavigationMenuManagementService;
  private messageCenterConfigService: MessageCenterConfigService;
  scheduleNotPost: boolean = false;
  private appSettingManageService: AppSettingsManageService;
  public ScheduleRotationTemplate: boolean = false;
  public WorkerDupesReport: boolean= false;
  public isNextgenPayrollEnabled: boolean = false;
  public isIdealScheduleImportEnabled: boolean = false;
  public screenWidth: number;
  constructor(
    menuManagement: NavigationMenuManagementService,
    messageCenterConfigService: MessageCenterConfigService,
    private appStateBusService: ApplicationStateBusService,
    private benAdminService: BenAdminMenuManagementService,
    private redirectService: NavigationMenuRedirectService,
    private elementRef: ElementRef,
    private renderer: Renderer2, appSettingManageService: AppSettingsManageService
  ) {
    this.messageCenterConfigService = messageCenterConfigService;
    this.menuManagement = menuManagement;
    this.showFavorites = false;
    this.posTop = 184;
    this.posLeft = 10;
    this.iconShift = 12;
    this.appSettingManageService = appSettingManageService;
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
  }
  public onLeftNavScroll() {
    if (this.isMobile()) { return; }
    var navTop = this.navMenu.nativeElement.scrollTop;
    var modifiedTop = (-42 - navTop);
    var natElement = document.querySelector('.expanded-scroll ul');
    if (natElement) {
      this.renderer.setStyle(natElement, 'margin-top', `${modifiedTop}px`);
      var divElement = document.querySelector('.expanded-scroll ul div');
      if (divElement) {
        this.renderer.setStyle(divElement, 'max-height', `initial`);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOpened']) {
      if (!this.isOpened) {
        this.expandedTopLevelId = 0;
        this.collapseAnothersTopItems(null);
      }
    }
  }

  public ngOnInit(): void {
    this.getSettings();
    this.applicationSubscription = this.appStateBusService.subscribeToSelectApp((app: UserApplication) => {
      scheduleMicrotask(() => {
        this.application = app;
        this.reselectMenu();
      });
    });

    this.loadedSubscription = this.menuManagement.onLoaded$.subscribe((menuItems: NavigationMenuItem[]) => {
      this.menuItems = menuItems;
      this.noData = (!this.menuItems || this.menuItems.length === 0);
      if (this.noData) {
        return;
      }
      this.toggleMessageCenterCombined(this.menuItems);
      this.checkGroupItemsChildren(this.menuItems);
      this.reselectMenu();
      Promise.resolve(this.benAdminService.initialize());
    });

    this.menuChangedSubscription = this.appStateBusService.menuSelected$.subscribe((menuItems: NavigationMenuItem) => {
      scheduleMicrotask(() => {
        if (this.noData) {
          return;
        }
        this.reselectMenu();
      });
    });

    if (this.isMobile()) {
      this.posTop = 135;
    }
  }

  public toggleTopItem(menuItem: NavigationMenuItem): void {
    menuItem.isExpanded = !menuItem.isExpanded;
    if (!menuItem.isExpanded) {
      this.expandedTopLevelId = 0;
      return;
    }
    this.expandedTopLevelId = menuItem.id;
    this.collapseAnothersTopItems(menuItem);

  }

  public collapseAnothersTopItems(expandedIdAtTop: NavigationMenuItem): void {
    _.forEach(this.menuItems, (topItem: NavigationMenuItem) => {
      if (!expandedIdAtTop || topItem.id !== expandedIdAtTop.id || topItem.type !== expandedIdAtTop.type) {
        topItem.isExpanded = false;
        _.forEach(topItem.childs, (groupItem: NavigationMenuItem) => {
          groupItem.isExpanded = false;
          _.forEach(groupItem.childs, (item: NavigationMenuItem) => {
            item.isExpanded = false;
          });
        });
      }
    });
  }

  public toggleGroupItem(topMenuItem: NavigationMenuItem, menuItem: NavigationMenuItem): void {
    menuItem.isExpanded = !menuItem.isExpanded;
    if (!menuItem.isExpanded) {
      this.expandedGropLevelId = 0;
      return;
    }
    this.expandedTopLevelId = topMenuItem.id;
    this.expandedGropLevelId = menuItem.id;
    _.forEach(topMenuItem.childs, (groupItem: NavigationMenuItem) => {
      if (groupItem.id !== menuItem.id || groupItem.type !== menuItem.type) {
        groupItem.isExpanded = false;
        _.forEach(groupItem.childs, (item: NavigationMenuItem) => {
          item.isExpanded = false;
        });
      }
    });
  }

  public toggleItem(menuItem: NavigationMenuItem): void {
    menuItem.isSelected = true;
    menuItem.isExpanded = !menuItem.isExpanded;
    this.menuItems
      .filter((mi: NavigationMenuItem) => mi.id !== menuItem.id)
      .forEach((mi: NavigationMenuItem) => mi.isExpanded = mi.isSelected = false);
  }

  public selectGlobalMenuItem(topMenuItem: NavigationMenuItem, groupMenuItem: NavigationMenuItem, menuItem: NavigationMenuItem): void {
    this.expandedTopLevelId = 0;
    this.expandedGropLevelId = 0;
    _.forEach(this.menuItems, (topItem: NavigationMenuItem) => {
      if (topItem.id !== topMenuItem.id || topItem.type !== topMenuItem.type) {
        topItem.isExpanded = false;
      } else {
        topItem.isExpanded = true;
      }
    });
    if (groupMenuItem) {
      _.forEach(topMenuItem.childs, (groupItem: NavigationMenuItem) => {
        groupItem.isExpanded = false;
        if (groupItem.id !== groupMenuItem.id || groupItem.type !== groupMenuItem.type) {
          groupItem.isSelected = false;
        }
      });
      _.forEach(groupMenuItem.childs, (item: NavigationMenuItem) => {
        item.isExpanded = false;
        if (item.id !== menuItem.id || item.type !== menuItem.type) {
          item.isSelected = false;
        }
      });
    }
    let item: NavigationMenuItem = groupMenuItem ? groupMenuItem : topMenuItem;
    if (this.isDisabled(item)) {
      return;
    }

    this.redirectService.open(item);
  }

  public selectMenuItem(topMenuItem: NavigationMenuItem, groupMenuItem: NavigationMenuItem, menuItem: NavigationMenuItem): void {
    this.expandedTopLevelId = topMenuItem.id;
    this.expandedGropLevelId = 0;
    menuItem.isSelected = true;
    _.forEach(this.menuItems, (topItem: NavigationMenuItem) => {
      if (topItem.id !== topMenuItem.id || topItem.type !== topMenuItem.type) {
        topItem.isExpanded = false;
      } else {
        topItem.isExpanded = topItem.isSelected = true;
      }
    });
    _.forEach(topMenuItem.childs, (groupItem: NavigationMenuItem) => {
      groupItem.isExpanded = false;
      if (groupItem.id !== groupMenuItem.id || groupItem.type !== groupMenuItem.type) {
        groupItem.isSelected = false;
      }
    });
    _.forEach(groupMenuItem.childs, (item: NavigationMenuItem) => {
      item.isExpanded = false;
      if (item.id !== menuItem.id || item.type !== menuItem.type) {
        item.isSelected = false;
      }
    });

    if (!this.selectedApplicationMenu || this.selectedApplicationMenu.applicationId !== topMenuItem.applicationId) {
      this.selectedApplicationMenu = topMenuItem;
    }
    if (this.isDisabled(menuItem)) {
      return;
    }
    this.appStateBusService.clickMenuItem(menuItem);
    this.redirectService.openByLink(menuItem);
    if (groupMenuItem.childLevelsCount) {
      if (groupMenuItem.childLevelsCount === 3) {
        this.toggleTopItem(topMenuItem);
      }
    }
  }

  public isExpanded(menuItem: NavigationMenuItem): boolean {
    return menuItem.isExpanded;
  }
  public menuMouseEnter(menuItem: NavigationMenuItem): void {
    if (this.isMobile() || !menuItem || this.isHidden(menuItem)) {
      return;
    }
    menuItem.isHovered = true;
  }

  public menuMouseLeave(menuItem: NavigationMenuItem): void {
    if (this.isMobile() || !menuItem || this.isHidden(menuItem)) {
      return;
    }
    menuItem.isHovered = false;
  }

  public isDisabled(menuItem: NavigationMenuItem): boolean {
    return !this.menuManagement.isMenuSupportOrgLevel(menuItem);
  }

  public isHidden(menuItem: NavigationMenuItem): boolean {
    return this.isMobile() && !menuItem.isAvailableOnMobile;
  }

  public isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }

  public isLastVisibleItem(groupMenuItem: NavigationMenuItem, menuIndex: number): boolean {
    if (!this.isMobile()) {
      return menuIndex === (groupMenuItem.childs.length - 1);
    }
    const nextVisible = _.find(groupMenuItem.childs, (item: NavigationMenuItem) => item.isAvailableOnMobile, menuIndex + 1);
    return !nextVisible;
  }

  private reselectMenu(): void {
    if (!this.menuItems) {
      this.selectedApplicationMenu = null;
      return;
    }
    if (!this.application || !this.application.id) {
      this.reexpandMenu(0);
      return;
    }
    this.selectedApplicationMenu = _.find(this.menuItems, (menuItem: NavigationMenuItem) => {
      return menuItem.applicationId === this.application.id;
    });
    if (this.selectedApplicationMenu) {
      this.reexpandMenu(this.application.id);
    }
  }

  private reexpandMenu(selectedAppId: number): void {
    _.forEach(this.menuItems, (menuItem: NavigationMenuItem) => {
      if (selectedAppId === -1 || menuItem.applicationId !== selectedAppId) {
        menuItem.isSelected = false;
      } else {
        menuItem.isSelected = true;
      }
      if (menuItem.id === this.expandedTopLevelId) {
        menuItem.isExpanded = true;
        _.forEach(menuItem.childs, (groupMenuItem: NavigationMenuItem) => {
          if (groupMenuItem.id === this.expandedGropLevelId) {
            groupMenuItem.isExpanded = true;
          }
        });
      } else {
        menuItem.isExpanded = false;
      }
    });
  }


  public getThreeLevelMenuItem(menuItems: NavigationMenuItem[]): NavigationMenuItem {
    let threeLevelMenuItem: NavigationMenuItem;
    _.forEach(menuItems, (menuItem: NavigationMenuItem) => {
      if (menuItem.childLevelsCount === 3) {
        threeLevelMenuItem = menuItem;
      }
    });
    return threeLevelMenuItem;
  }

  public checkGroupItemsChildren(menuItems: NavigationMenuItem[]) {
    let threeLevelMenuItem = this.getThreeLevelMenuItem(menuItems);
    if (threeLevelMenuItem && threeLevelMenuItem.childs) {
      _.forEach(threeLevelMenuItem.childs, (groupMenuItem: NavigationMenuItem) => {
        this.checkAllChildrenDisabled(groupMenuItem);
      });
    }
  }

  public checkAllChildrenDisabled(groupMenuItem: NavigationMenuItem) {
    if (groupMenuItem && groupMenuItem.childs) {
      groupMenuItem.allChildrenDisabled = true;
      _.forEach(groupMenuItem.childs, (menuItem: NavigationMenuItem) => {
        let menuDisabled = this.isDisabled(menuItem);
        if (!menuDisabled) {
          groupMenuItem.allChildrenDisabled = false;
        }
      });
    }
  }

  public toggleMessageCenterCombined(menuItems: NavigationMenuItem[]) {
    _.forEach(menuItems, (menuItem: NavigationMenuItem) => {
      if (menuItem.name == 'message_center') {
        this.messageCenterConfigService.switchMessageCenterUrl(true);
      }
      else if (menuItem.name == 'message_center_sms') {
        this.messageCenterConfigService.switchMessageCenterUrl(false);
      }
    });
  }

  public onToggleFavoritesView(event: MouseEvent) {
    this.showFavorites = !this.showFavorites;
  }

  @HostListener('document:keyup', ['$event'])
  public pressEscape(event: KeyboardEvent): void {
    if (this.showFavorites && event.code === 'Escape') {
      this.showFavorites = false;
    }
  }

  @HostListener('document:click', ['$event'])
  public clickOutside(event: MouseEvent): void {
    const elem: HTMLElement = this.elementRef.nativeElement;
    let node = event.target as Node;
    if (elem && this.showFavorites) {
      if (!elem.contains(node) && node.parentElement != null) {
        this.showFavorites = false;
      }
    }
  }

  public async getSettings(): Promise<void> {
    let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
    this.scheduleNotPost = appServerConfig.ScheduleNotPosted;
    this.ScheduleRotationTemplate = appServerConfig.ScheduleRotationTemplate;
    this.WorkerDupesReport = appServerConfig.WorkerDupesReport;
    this.isNextgenPayrollEnabled = appServerConfig.IsNextgenPayrollEnabled;
    this.isIdealScheduleImportEnabled = appServerConfig.isDownloadImportOptionEnabled;
  }

  public isMenuItemEnabled(item: NavigationMenuItem): boolean {
    if (item.name === 'schedules_not_posted')
      return (item.name === 'schedules_not_posted' ? this.scheduleNotPost : true);
    else if (item.name === 'scheduled_rotations')
      return (item.name === 'scheduled_rotations' ? this.ScheduleRotationTemplate : true);
    else if (item.name === 'pbj-duplicate-workers')
      return (item.name === 'pbj-duplicate-workers' ? this.WorkerDupesReport : true);
    else if (item.name === 'code_mapping')
      return (item.name === 'code_mapping' ? this.isNextgenPayrollEnabled : true)
    else if(item.name === 'File_Uploads')
      return (item.name === 'File_Uploads' ? this.isIdealScheduleImportEnabled : true);
    else
      return true;
  }

}
