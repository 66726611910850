<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <div *ngIf="!state.isLoading && getCurrentOrgLevel.id == 1" class="slx-el-actions-bar" [ngClass]="{'inactive': stateManagement.lockActions}">
      <button class="slx-el-action-button" (click)="openVirtualClockModal()">Create Virtual Clock</button>
    </div>

    <!-- Table header -->
    <div class="slx-main-content-indents slx-high-box__body page-overflow">
      <div class="slx-el-table-header slx-el-row border-top">
        <div class="slx-el-col hxs"></div>
        <div *ngIf="stateManagement.canEdit" class="slx-el-col ohxs"></div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Organization</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Department</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Clock Name</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Clock Model</span>
        </div>
        <div class="slx-el-col ohxs">
          <span class="slx-el-header-title">Clock ID</span>
        </div>
        <div class="slx-el-col ohxs">
          <span class="slx-el-header-title">Physical ID</span>
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-header-title">IP Address</span>
        </div>
      </div>

      <!-- Timeclocks list -->
      <slx-editable-list [data]="container?.records" class="slx-el-table-body" (editStart)="onItemEditStart($event)"
        (editCancel)="onItemEditCancel($event)" (selectionChange)="onSelectionChange($event)"
        (itemSave)="onItemSave($event)" [editable]="false" [selectable]="false">
        <ng-template slx-editable-list-renderer let-context>
          <slx-timeclock-renderer slx-list-highlight slx-list-actions [context]="context"></slx-timeclock-renderer>
        </ng-template>
      </slx-editable-list>
    </div>
  </div>

  <!-- Modal for creating virtual timeclocks -->
  <div class="modal-overlay" *ngIf="showVirtualClockModal && getCurrentOrgLevel.id == 1">
    <div class="modal-content">
      <h3>Create Virtual Timeclocks</h3>

      <!-- Show spinner with padding when loading -->
      <div [ngClass]="{'padded-spinner': state.isLoading}">
        <slx-spinner [show]="state.isLoading"></slx-spinner>
      </div>

      <!-- Modal body shows only if not loading -->
      <div *ngIf="!state.isLoading" class="modal-body">
        <div class="columns">
          <div class="column">
            <h3>Timeclocks</h3>
            <ul class="selection-list">
              <li *ngFor="let timeclock of filteredTimeclocks">
                <label>
                  <input type="checkbox" (change)="toggleTimeclockSelection(timeclock.id)">
                  {{ timeclock.name || 'Unnamed Timeclock' }}
                </label>
              </li>
            </ul>
          </div>
          <div class="column">
            <h3>Organizations</h3>
            <ul class="selection-list">
              <li *ngFor="let org of allOrganizations">
                <label>
                  <input type="checkbox" (change)="toggleOrgSelection(org.id)">
                  {{ org.name || 'Unnamed Organization' }}
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Sticky footer with filtering options and flair buttons -->
      <div class="modal-footer sticky-footer">
        <div class="filter-container">
          <div class="filter-item">
            <select id="filterType" [(ngModel)]="filterType">
              <option value="name">Timeclock Name</option>
              <option value="organization">Organization</option>
            </select>
          </div>
          <div class="filter-item">
            <input type="text" [(ngModel)]="filterText" (input)="applyFilter()" placeholder="Filter Timeclocks..." />
          </div>
        </div>

        <button class="flair-button green" (click)="createVirtualClocks()"
          [disabled]="!canCreateVirtualClocks() || state.isLoading"
          [ngClass]="{'disabled': !canCreateVirtualClocks() || state.isLoading}">
          Create
        </button>
        <button class="flair-button gray" (click)="closeVirtualClockModal()">Close</button>
      </div>
    </div>
  </div>
</slx-spinner>